<template>
    <section>
        <div id="sign-in-button" />
        <navbar-auth />
        <div class="container-fluid px-0 position-relative" style="height:calc(100vh - 70px);background-color:#F5F5F6;">
            <!-- Backgrounds -->
            <div
            class="position-absolute w-100 h-100 image-background"
            :style="`background-image: url('${_.isEmpty(urlBackground) ? '/img/no-imagen/sin_imagen.png' : urlBackground}');`"
            />
            <!-- Backgrounds -->
            <div class="row mx-0 justify-content-end align-items-center" style="height:100%;background-color:#F5F5F6;">
                <div class="box mr-3">
                    <ValidationObserver v-if="paso == 1" v-slot="{ invalidate }" ref="validacion">
                        <div class="row mx-0 justify-center text-general f-600 my-2" style="font-size:37px">
                            Recuperar Contraseña
                        </div>
                        <div class="row mx-0 text-general f-15 justify-center px-5 mb-3 mt-4">
                            <p class="col-auto text-center">
                                Introduce el correo electrónico o el número de <br /> teléfono para buscar tu cuenta.
                            </p>
                        </div>
                        <div class="row justify-center mb-3 mt-4">
                            <ValidationProvider v-slot="{errors}" tag="div" rules="required|max:500" name="usuario" class="col-8 position-relative">
                                <div v-if="errores.error == 'UT001'" class="position-absolute alert-message bg-white br-12" style="left:-360px;top:-45px;">
                                    <p class="text-general-red f-30 f-600 mt-3 text-center">
                                        Espera
                                    </p>
                                    <p class="text-general f-15 mt-1 f-500 text-center">
                                        Estás intentando ingresar como un administrador.
                                    </p>
                                    <p class="text-green cr-pointer f-15 mt-1 f-500 text-center" @click="$router.push({name: 'login.form.admin'})">
                                        Ir a la aplicación de administrador
                                    </p>
                                </div>
                                <div v-else-if="errores.error == 'UT002'" class="position-absolute alert-message bg-white br-12" style="left:-360px;top:-45px;">
                                    <p class="text-general-red f-30 f-600 mt-3 text-center">
                                        Espera
                                    </p>
                                    <p class="text-general f-15 mt-1 f-500 text-center">
                                        Estás intentando ingresar como un {{$config.vendedor}}.
                                    </p>
                                    <p class="text-green cr-pointer f-15 mt-1 f-500 text-center" @click="$router.push({name: 'login.form.leechero'})">
                                        Ir a la aplicación de {{$config.vendedor}}
                                    </p>
                                </div>
                                <div v-else-if="errores.error == 'UT003'" class="position-absolute alert-message bg-white br-12" style="left:-360px;top:-45px;">
                                    <p class="text-general-red f-30 f-600 mt-3 text-center">
                                        Espera
                                    </p>
                                    <p class="text-general f-15 mt-1 f-500 text-center">
                                        Estás intentando ingresar como un cliente.
                                    </p>
                                    <p class="text-green cr-pointer f-15 mt-1 f-500 text-center" @click="$router.push({name: 'login.form.cliente'})">
                                        Ir a la aplicación de cliente
                                    </p>
                                </div>
                                <error-alert v-if="showErrorAlert" :tipo="errorAlertType" :titulo="errorAlertType == 4 ? 'Error' : 'Espera' " />

                                <el-select
                                v-model="pais"
                                placeholder="Seleccione un país"
                                filterable
                                class="w-100 br-6 mb-2"
                                >
                                    <el-option
                                    v-for="(item, i) in proyecto.paises"
                                    :key="i"
                                    :label="item.nombre"
                                    :value="item.id"
                                    />
                                </el-select>
                                <el-input v-model="payload.usuario" class="w-100" placeholder="correo@ejemplo.com">
                                    <i
                                    v-if="errores.noExisteUsuario || errors[0]"
                                    slot="suffix"
                                    style="color:#FF3333;padding-top:10px;"
                                    class="icon-attention-alt f-20 d-flex"
                                    />
                                </el-input>
                            </ValidationProvider>
                        </div>
                        <div class="row justify-center mx-0">
                            <div class="col-8 px-2">
                                <button class="btn w-100 cr-pointer text-white bg-general br-8 d-middle-center" style="height:44px;" @click="generarCodigoRecuperacion(invalidate)">
                                    Enviar
                                </button>
                            </div>
                        </div>
                    </ValidationObserver>
                    <template v-else-if="paso == 2">
                        <div class="row mx-0 justify-center text-general mt-2 mb-3 f-600" style="font-size:37px;">
                            Verificar código
                        </div>
                        <div class="row mx-0 my-2 justify-center text-general">
                            <div class="col-auto text-center f-17">
                                Te enviamos un código al <br />
                                <span class="text-general-red f-600">{{ payload.usuario }}</span>
                            </div>
                        </div>
                        <div class="row mx-0 mt-3 text-center text-muted2 justify-center ">
                            <div class="col-auto px-0 mx-2" style="width:61px;">
                                <el-input
                                ref="cod1"
                                v-model="payload.codigo[0]"
                                maxlength="1"
                                class="w-100 input-codigo"
                                @input.self="next(2)"
                                />
                            </div>
                            <div class="col-auto px-0 mx-2" style="width:61px;">
                                <el-input
                                ref="cod2"
                                v-model="payload.codigo[1]"
                                maxlength="1"
                                class="w-100 input-codigo"
                                @input.self="next(3)"
                                @keyup.native.delete="back(1)"
                                />
                            </div>
                            <div class="col-auto px-0 mx-2" style="width:61px;">
                                <el-input
                                ref="cod3"
                                v-model="payload.codigo[2]"
                                maxlength="1"
                                class="w-100 input-codigo"
                                @input.self="next(4)"
                                @keyup.native.delete="back(2)"
                                />
                            </div>
                            <div class="col-auto px-0 mx-2" style="width:61px;">
                                <el-input
                                ref="cod4"
                                v-model="payload.codigo[3]"
                                maxlength="1"
                                class="w-100 input-codigo"
                                @input.self="next(5)"
                                @keyup.native.delete="back(3)"
                                />
                            </div>
                            <div class="col-auto px-0 mx-2" style="width:61px;">
                                <el-input
                                ref="cod5"
                                v-model="payload.codigo[4]"
                                maxlength="1"
                                class="w-100 input-codigo"
                                @input.self="next(6)"
                                @keyup.native.delete="back(4)"
                                />
                            </div>
                            <div class="col-auto px-0 mx-2" style="width:61px;">
                                <el-input
                                ref="cod6"
                                v-model="payload.codigo[5]"
                                maxlength="1"
                                class="w-100 input-codigo"
                                @keyup.native.delete="back(5)"
                                />
                            </div>
                        </div>
                        <div class="row mt-2 px-5">
                            <div v-if="errores.codigo" class="col mb-3 f-12 pl-5" style="color:#FF3333">
                                <p>
                                    El código ingresado no es válido
                                </p>
                            </div>
                        </div>
                        <div class="row justify-center my-2">
                            <div class="col f-15" style="color:#0892DD;" @click="generarCodigoRecuperacion(false, false)">
                                <p class="text-center cr-pointer">
                                    <template v-if="!timeOut">
                                        Volver a enviar el código
                                    </template>
                                    <template v-else>
                                        {{ segundos }} segundos
                                    </template>
                                </p>
                            </div>
                        </div>
                        <div class="row justify-center mx-0 my-2">
                            <div class="col-8 px-3">
                                <button class="btn w-100 cr-pointer text-white bg-general br-8 d-middle-center" style="height:44px;" @click="validarCodigoRecuperacion">
                                    Verificar
                                </button>
                            </div>
                        </div>
                    </template>
                    <ValidationObserver v-else-if="paso == 3" v-slot="{ invalid }" ref="validadorContrasena">
                        <div class="row mx-0 justify-center text-general mt-2 mb-3 f-600" style="font-size:37px;">
                            Nueva contraseña
                        </div>
                        <div class="row mx-0 text-center justify-center text-general f-15">
                            <div class="col-auto">
                                Introduce la nueva que contraseña que vas a usar a <br /> partir de ahora.
                            </div>
                        </div>
                        <div class="row mx-0 justify-center my-3">
                            <div style="width:351px;">
                                <ValidationProvider v-slot="{ errors }" :rules="reglas.contrasena" vid="password" name="contraseña">
                                    <el-input
                                    v-model="payload.password"
                                    class="w-100"
                                    placeholder="Nueva contraseña"
                                    maxlength="200"
                                    minlength="8"
                                    show-password
                                    />
                                    <span class="text-danger pl-3 f-11" v-text="errors[0]" />
                                    <!-- <p class="text-muted pl-2 f-12 mt-2">La contraseña debe tener mínimo 8 cacacteres y contener al menos 1 letra</p> -->
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="row mx-0 justify-center my-3">
                            <div style="width:351px;">
                                <ValidationProvider v-slot="{ errors }" rules="required_if:password|confirmed:password" name="confirmar contraseña">
                                    <el-input
                                    v-model="payload.validacionPassword"
                                    class="w-100"
                                    placeholder="Repita su contraseña"
                                    show-password
                                    />
                                    <span class="text-danger f-11" v-text="errors[0]" />
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="row justify-center mx-0">
                            <div class="col-7 px-0">
                                <div class="cr-pointer text-white bg-general br-8 d-middle-center" style="height:44px;" @click="restablecerContrasena(invalid)">
                                    Guardar e ingresar
                                </div>
                            </div>
                        </div>
                    </ValidationObserver>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import ServicioApi from '~/services/auth';
import ServicioApi2 from "~/services/tiendas/postulacion";
import registroService from '~/services/registro/registro'
import authService from '~/services/auth';
import { mapGetters } from "vuex";

const segundosDefault = 90
export default {
    layout: 'login',
    components: {
        errorAlert: () => import("./partials/errorAlert"),
    },
    data(){
        return{
            payload: {
                usuario: '',
                codigo: [],
                password: '',
                validacionPassword: ''
            },
            reglas: {
                contrasena: {
                    required: true,
                    min: 8,
                    max: 200,
                    regex: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*.?\-_\/&:,;{}+*'¿¡!]?)([A-Za-z\d@$!.%*?\-_\/&:,;{}+*'¿¡!]){8,200}$/
                }

            },
            errores: {
                error: false,
                codigo: false,
                noExisteUsuario: false,
                tipoUsuario: false,
                mensajes: {
                    'U001': 'El correo electrónico o el teléfono es erróneo. Verifique e intente nuevamente',
                    'C002': 'El código suministrado es incorrecto. Verifique e intente nuevamente',
                    'UT001': 'El tipo del usuario es inválido eres admin',
                    'UT002': 'El tipo del usuario es inválido eres leechero',
                    'UT003': 'El tipo del usuario es inválido eres cliente',
                }
            },
            timer: null,
            paso: 1,
            interval: null,
            timeOut: false,
            segundos: segundosDefault,
            tipoLogin: 2,
            indice: 0,
            pais: '',
            proyecto: {
                datos: {},
                paises: [],
            },
            showErrorAlert: false,
            errorAlertType: 1
        }
    },
    computed: {
        ...mapGetters({
            imgVendedor: 'x_parametros/imgVendedor'
        }),
        urlBackground(){
            return this.imgVendedor.contrasena[this.indice];
        },
    },
    async mounted(){
        setInterval(() => {
            let cantidad = this.imgVendedor.contrasena.length - 1
            this.indice++;
            if(this.indice >= cantidad ){
                this.indice = 0;
            }
        }, 5000);
        this.limpiar();
        this.copiarUsuario();
        this.tipoLogin = this.$route.params.tipoLogin ?? 2
        await this.consultarProyecto();
        await this.consultarPaisesProyecto(this.proyecto.datos.id);
        
    },
    methods: {
        async consultarProyecto(){
            try {
                const { data: { data }, } = await ServicioApi2.consultarProyecto();
                this.proyecto.datos = data;
            } catch (error){
                this.error_catch(error);
            }
        },
        async consultarPaisesProyecto(proyecto){
            try {
                const { data: { data }, } = await ServicioApi2.consultarPaises(proyecto);
                this.proyecto.paises = data;
            } catch (error){
                this.error_catch(error);
            }
        },
        
        limpiar(){
            this.payload = {
                usuario: '',
                codigo: [],
                password: '',
                validacionPassword: ''
            }

            this.$refs.validacion.reset();
        },
        copiarUsuario(){
            this.payload.usuario = this.$route.params.usuario
        },
        avanzarVerificacionCodigo(){
            this.errores.codigo = false
            this.paso = 2
        },
        avanzarCambiarContrasena(){
            this.paso = 3
        },
        async generarCodigoRecuperacion(esInvalido, avanzar = true){
            // if (esInvalido) return;

            if(!esInvalido && !avanzar){
                if (this.interval || this.timer){
                    return
                }
                this.interval = setInterval(() => {
                    this.segundos--;
                }, 1000)
                this.timer = setTimeout(() => {
                    this.timeOut = false
                    this.segundos = segundosDefault
                    clearTimeout(this.timer)
                    clearInterval(this.interval)
                    this.timer = null
                    this.interval = null
                }, segundosDefault * 1000);

                this.timeOut = true
            }

            try {
                let isEmail = this.payload.usuario.includes('@')
                if(isEmail){

                    const payload = {
                        usuario: this.payload.usuario,
                        tipo: this.tipoLogin
                    };
                    const { data } = await ServicioApi.generarCodigoRecuperacion(payload);
                    this.notificacion(
                        'Código enviado',
                        'El código de recuperación ha sido enviado al teléfono y/o correo asociados a tu cuenta',
                        'success'
                    );
                } else {
                    if(!this.pais) return this.notificacion('', 'Debe seleccionar un país', 'error')
                    const { data } = await authService.noExistsUser({usuario: this.payload.usuario})
                    console.log({exists: data.exists});
                    if(!data.exists){
                        this.showErrorAlert = true
                        this.errorAlertType = 4
                        return
                    }
                    if(data.exists){
                        if (data.user.tipo == 5){
                            this.showErrorAlert = true
                            this.errorAlertType = 2
                            return 
                        }

                        if (data.user.tipo == 1){
                            this.showErrorAlert = true
                            this.errorAlertType = 1
                            return
                        }
                    }
                    const { indicativo } = this.proyecto.paises.find((el) => el.id == this.pais)
                    const phoneNumber = `+${indicativo}${this.payload.usuario}`
                    const datos = await registroService.registrar(phoneNumber)
                    
                }
                if (avanzar) this.avanzarVerificacionCodigo();
            } catch (error){
                // this.error_catch(error);
                console.log('epa');
                this.capturarError(error);
            }
        },
        async validarCodigoRecuperacion(){
            if (this.payload.codigo.length !== 6) return;

            try {
                let isEmail = this.payload.usuario.includes('@')
                let codigo = this.payload.codigo.join('')
                if(isEmail){
                    const payload = {
                        usuario: this.payload.usuario,
                        codigo
                    };

                    const { data } = await ServicioApi.validarCodigoRecuperacion(payload);
                    this.notificacion('Verificado', 'Código ha sido verificado con éxito', 'success');
                } else {
                    const p =  await registroService.verificarCode(codigo)
                }
                this.avanzarCambiarContrasena ();
            } catch (error){
                switch (error.code){
                case 'auth/invalid-verification-code':
                    this.notificacion('Error', 'El código que introdujo no es el correcto', 'error')
                    break;

                default:
                    this.capturarError(error)
                }
                
            }
        },
        async restablecerContrasena(){
            try {
                const payload = {
                    usuario: this.payload.usuario,
                    password: this.payload.password
                };
                const { data } = await ServicioApi.restablecerContrasena(payload);
                this.notificacion(
                    'Contraseña actualizada',
                    'La contraseña ha sido actualizada con éxito, por favor intenta ingresar nuevamente',
                    'success'
                );
                if(data.usuario.tipo == 2 || data.usuario.tipo == 4){
                    this.$router.push({name: 'login.form.leechero', params: { ingresoLeechero: false }})
                }else if(data.usuario.tipo  == 5){
                    this.$router.push({name: 'login.form.cliente'})
                }
            } catch (error){
                this.error_catch(error)
            }
        },
        capturarError(error){
            const codigo = error?.response?.data?.codigo;

            if (!!!codigo){
                this.error_catch(error);
                return;
            }

            this.notificacion('Verifique los datos', this.errores.mensajes[codigo], 'error');
            this.errores.error = codigo;
            this.errores.codigo = (codigo === 'C002');
            this.errores.noExisteUsuario = (codigo === 'U001');
            this.errores.tipoUsuario = (codigo === 'UT003' || codigo === 'UT001' || codigo === 'UT002');
        },
        next(number){
            this.$nextTick(function(){
                if(this.$refs[`cod${number - 1}`].value != "" && number > 1){
                    this.$refs[`cod${number}`].focus()
                }
            })
        },
        back(number){
            if(this.$refs[`cod${number}`].value){
                this.$nextTick(function(){
                    this.$refs[`cod${number}`].focus()
                })
            }
        },
    }
}
</script>

<style lang="scss" scoped>

.image-background{
    z-index:0;
    background-size:cover;
    background-position: left center;
    background-attachment: fixed;
    background-repeat:no-repeat;
    transition: all 1s ease-out !important;
}

.alert-message{
    box-shadow: 0px 1px 4px #00000014;
    width: 338px;
    height: 138px;
    &::before{
        content: ' ';
        position: absolute;
        width: 0;
        height: 0;
        left: auto;
        right: -40px;
        top: 38px;
        bottom: auto;
        border: 25px solid;
        border-color: transparent transparent transparent white;
    }
}
.text-green{
    color: #28D07B !important;
}
.navbar-postulacion{
    top: 0px;
    left: 0px;
    width: calc(100vw);
    height: 70px;
    display: flex;
    align-items: center;
    background-color: var(--color-general);
    box-shadow: 0px 2px 8px #00000029;
}
.box{
    box-shadow: 0px 1px 4px #00000014;
    background-color: #FFFFFF;
    z-index: 5;
    border-radius: 12px;
    width: 485px;
    height: 364px;
}
.text-green{
    color: #28D07B;
}
</style>
